import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { getTimesheetsToCounterSign } from "../../../slices/timesheetsSlice";
import { getUsers } from "../../../slices/accountSlice";
import TimesheetSummary from "../Timesheets/TimeSheets/TimesheetSummary/TimesheetSummary";
import { InfoBox } from "../../Layout/InfoBox/InfoBox";
import { Spinner } from "../../../components/Layout/Spinner/Spinner.jsx";

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    width: "99%",
    padding: "20px",
  },

  section: {
    minHeight: "350px",
  },
}));

export default function TODO() {
  const classes = useStyles();
  const { t } = useTranslation(["todo"]);
  const dispatch = useDispatch();

  const users = useSelector((state) => state.account.users);
  const todo = useSelector((state) => state.timesheets.todo);

  const [toCountersignByUser, setToCountersignByUser] = useState({});
  const [loading, setLoading] = useState({ countersign: true });

  useEffect(() => {
    const TimesheetsToCountersignSortedByUser = todo.toCounterSign.reduce(
      (acc, timesheet) => {
        if (acc[timesheet.user_id]) {
          acc[timesheet.user_id].push(timesheet);
        } else {
          acc[timesheet.user_id] = [timesheet];
        }
        return acc;
      },
      {}
    );
    setToCountersignByUser(TimesheetsToCountersignSortedByUser);
  }, [todo]);

  useEffect(() => {
    dispatch(getTimesheetsToCounterSign())
      .unwrap()
      .then(() => setLoading({ ...loading, countersign: false }));
  }, []);

  useEffect(() => {
    dispatch(getUsers({ displayArchived: true }));
  }, []);

  const findUser = (userId) => {
    return users.find((user) => user.id == userId);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h1"> {t("todo")}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={loading.countersign ? classes.section : ""}
        >
          <Card marginTop={6}>
            {/* To Countersign */}
            <CardHeader
              title={t("to_countersign")}
              titleTypographyProps={{ variant: "h2" }}
            />
            <CardContent className={loading.countersign ? classes.section : ""}>
              {Object.keys(toCountersignByUser).length ? (
                Object.keys(toCountersignByUser).map((userId) => (
                  <Grid container spacing={2} key={userId}>
                    <Grid item xs={12}>
                      <Typography variant="h3">
                        {findUser(userId)?.full_name || userId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} marginBottom={4}>
                      <Grid container spacing={2} key={userId}>
                        {toCountersignByUser[userId].map((timesheet) => (
                          <TimesheetSummary
                            key={timesheet.id}
                            timesheet={timesheet}
                            // userSummary={userSummaries[userid]}
                            user={findUser(userId) || {}}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : loading.countersign ? (
                <Spinner />
              ) : (
                <InfoBox level="info">{t("nothing_to_countersign")}</InfoBox>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
