export const app = {
  name: "Feuille de temps v2",
};

export const sentryUrl =
  "https://3989a3f32e264ac98cd95e54184b9bb6@o413315.ingest.sentry.io/6302850";

export const LANGUAGES_LABELS = [
  { code: "fr", text: "Francais" },
  { code: "en", text: "English" },
];

export const stripe = {
  publicApiKEy:
    "pk_live_51KPTUILrgkwe3weVsUi3PlLpflu8Y5lz9EJEYDibKf1zTRhP784zRUIlhW70QzGfRh5ljgPg5sBUXRb3kumDXzl100ii0BTjVi",
};

export const BOOK_DEMO_URL =
  "https://fantastical.app/aynils/presentation-de-feuille-de-temps";

export const help_center_urls = {
  "fr-ca": {
    intro: "https://feuilledetemps.ca/docs/intro?utm_campaign=app",
    fill_timesheet:
      "https://feuilledetemps.ca/docs/tutorial-employee/remplir-feuille-de-temps?utm_campaign=app",
  },
};
