import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { displayAlert } from "../../../../slices/feedbackSlice";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { updateUser } from "../../../../slices/accountSlice";
import { InfoBox } from "../../../Layout/InfoBox/InfoBox";
import dayjs from "dayjs";
import ArchiveUserModal from "../ArchiveUserModal/ArchiveUserModal";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  contractEndDateLabel: {
    alignSelf: "center",
    display: "inline-flex",
  },
}));

export default function UserSettingsModal({ display, handleClose, user }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fields, setFields] = useState({});
  const [archiveUserModalOpen, setArchiveUserModalOpen] = useState(false);
  const [hasContractEndDate, setHasContractEndDate] = useState(
    !!user.contract_end_date
  );

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("users");

  useEffect(() => {
    setFields({
      email: {
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "email",
        label: t("email"),
        name: "email",
        autoComplete: "email",
        value: user.email,
        InputLabelProps: { shrink: true },
      },
      firstName: {
        autoComplete: "firstName",
        name: "firstName",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "firstName",
        label: t("first_name"),
        value: user.first_name,
        InputLabelProps: { shrink: true },
      },
      lastName: {
        autoComplete: "lastName",
        name: "lastName",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "lastName",
        label: t("last_name"),
        value: user.last_name,
        InputLabelProps: { shrink: true },
      },
      contractStartDate: {
        name: "contractStartDate",
        type: "date",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "contractStartDate",
        label: t("date"),
        value: user.contract_start_date
          ? dayjs(user.contract_start_date)
          : null,
        InputLabelProps: { shrink: true },
      },
      contractEndDate: {
        name: "contractStartDate",
        type: "date",
        variant: "outlined",
        required: false,
        fullWidth: true,
        id: "contractEndDate",
        label: t("date"),
        value: user.contract_end_date ? dayjs(user.contract_end_date) : null,
        InputLabelProps: { shrink: true },
      },
      reportedSick: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        value: user.initial_cumulated_sick_hours || 0,
        id: "reportedSick",
        label: t("plural.hour"),
        name: "reportedSick",
        InputLabelProps: { shrink: true },
      },
      reportedVacation: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        value: user.initial_cumulated_vacation_hours || 0,
        id: "reportedVacation",
        label: t("plural.hour"),
        name: "reportedVacation",
        InputLabelProps: { shrink: true },
      },
      reportedOvertime: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        value: user.initial_cumulated_overtime_hours || 0,
        id: "reportedOvertime",
        label: t("plural.hour"),
        name: "reportedOvertime",
        InputLabelProps: { shrink: true },
      },
      archived: {
        type: "archived",
        variant: "archived",
        required: false,
        fullWidth: true,
        value: user.archived || false,
        id: "archived",
        label: t("archived"),
        name: "archived",
        InputLabelProps: { shrink: true },
      },
    });
  }, [t, user]);

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };
  const handleChangeDate = (value, id) => {
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  const toggleHascontractEndDate = () => {
    setHasContractEndDate(!hasContractEndDate);
  };

  const handleSuccess = () => {
    dispatch(displayAlert(t("settings_update_success"), "success"));
    setLoading(false);
    handleClose();
  };

  const handleError = (err) => {
    dispatch(displayAlert(t("error"), "error"));
    setLoading(false);
  };

  const handleSetArchivedFlag = (flag) => {
    console.log("coucou", flag);
    setFields({
      ...fields,
      archived: {
        ...fields.archived,
        value: flag,
      },
    });
  };

  const handleArchiveUserModalClose = () => {
    setArchiveUserModalOpen(false);
  };
  const handleArchiveUserModalCancel = () => {
    setArchiveUserModalOpen(false);
    handleSetArchivedFlag(false);
  };
  const handleArchiveUserModalOpen = () => {
    handleSetArchivedFlag(true);
    setArchiveUserModalOpen(true);
  };
  const handleSave = () => {
    setLoading(true);
    const userData = Object.values(fields).reduce((acc, elem) => {
      acc[elem.id] = elem.value;
      return acc;
    }, {});

    userData.id = user.id;

    userData.contractStartDate =
      userData.contractStartDate.format("YYYY-MM-DD");

    userData.contractEndDate =
      hasContractEndDate && userData.contractEndDate
        ? userData.contractEndDate.format("YYYY-MM-DD")
        : null;

    dispatch(updateUser(userData))
      .unwrap()
      .then(() => handleSuccess())
      .catch((err) => handleError(err));
  };

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      {archiveUserModalOpen && (
        <ArchiveUserModal
          display={archiveUserModalOpen}
          handleClose={handleArchiveUserModalClose}
          handleCancel={handleArchiveUserModalCancel}
          handleSave={handleSave}
        />
      )}
      <DialogTitle variant="h3">
        {t("update_user_settings")}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form onChange={handleChange}>
          <Grid container spacing={3} marginTop={"1px"}>
            <Grid item xs={12} md={6}>
              <TextField {...fields.firstName} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField {...fields.lastName} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...fields.email} />
            </Grid>
            <Grid item xs={12} display={"flex"}>
              <Typography
                component="label"
                htmlFor={"contractStartDate"}
                variant="h5"
              >
                {t("contract_start_date")}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"}>
              <InfoBox level="info">{t("contract_start_date_info")}</InfoBox>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                {...fields.contractStartDate}
                onChange={(value) =>
                  handleChangeDate(value, "contractStartDate")
                }
              />
            </Grid>
            <Grid item sm={6} display={"flex"} justifyCenter>
              <Checkbox
                checked={hasContractEndDate}
                onClick={toggleHascontractEndDate}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography
                component={"span"}
                className={classes.contractEndDateLabel}
              >
                {t("contract_end_date_determined")}
              </Typography>
            </Grid>
            {hasContractEndDate && (
              <>
                <Grid item xs={12} display={"flex"}>
                  <Typography
                    component="label"
                    htmlFor={"contractEndDate"}
                    variant="h5"
                  >
                    {t("contract_end_date")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    {...fields.contractEndDate}
                    onChange={(value) =>
                      handleChangeDate(value, "contractEndDate")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InfoBox level="info">{t("contract_end_date_info")}</InfoBox>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography component="h3" variant="h3" marginTop={"1rem"}>
                {t("reported_data")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="label"
                    htmlFor={"reportedSick"}
                    variant="h5"
                  >
                    {t("sick")}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField {...fields.reportedSick} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="label"
                    htmlFor={"reportedVacation"}
                    variant="h5"
                  >
                    {t("vacation")}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField {...fields.reportedVacation} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    component="label"
                    htmlFor={"reportedOvertime"}
                    variant="h5"
                  >
                    {t("overtime")}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField {...fields.reportedOvertime} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.actions}>
            <Button
              variant="contained"
              component="label"
              color="secondary"
              onClick={handleClose}
              sx={{ height: "100%" }}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="outlined"
              component="label"
              color="secondary"
              onClick={handleArchiveUserModalOpen}
              sx={{ height: "100%" }}
            >
              {t("archive")}
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              component="label"
              color="primary"
              onClick={handleSave}
              sx={{ height: "100%" }}
            >
              {t("save")}
            </LoadingButton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const hasUserSpecificRules = (userConfig, organizationConfig) => {
  const params = [
    "worked_hours_per_week",
    "max_cumulated_sick_hours",
    "max_cumulated_vacation_hours",
    "max_cumulated_overtime_hours",
    "max_reported_sick_hours",
    "max_reported_vacation_hours",
    "max_reported_overtime_hours",
  ];

  const timeParams = ["sick_time_per_period", "vacation_time_per_period"];

  if (userConfig && organizationConfig) {
    const paramsEquals = params.map((param) => {
      return (
        userConfig[param] === null ||
        (userConfig[param] !== undefined &&
          organizationConfig[param] !== undefined &&
          userConfig[param] === organizationConfig[param])
      );
    });
    const timeParamsEquals = timeParams.map((param) => {
      return (
        userConfig[param] === null ||
        userConfig[param].time === organizationConfig[param].time
      );
    });

    return [...paramsEquals, ...timeParamsEquals].some((value) => !value);
  }
};
