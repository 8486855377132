import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import {
  displayStatus,
  formatDate,
  replaceObjectWithKey,
  round,
} from "../../../utils/helpers";
import Button from "@mui/material/Button";
import { InfoBox } from "../../Layout/InfoBox/InfoBox";
import { LoadingButton } from "@mui/lab";
import { displayAlert } from "../../../slices/feedbackSlice";
import {
  getSummaryForSelf,
  getTimesheet,
  getUserSummary,
  updateTimesheet,
} from "../../../slices/timesheetsSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UserSummary from "../Users/UserSummary/UserSummary";
import { getSelf, getUser } from "../../../slices/accountSlice";
import OverflowTooltip from "../../Layout/OverflowTooltip/OverflowTooltip";
import { Spinner } from "../../Layout/Spinner/Spinner";
import { Tooltip } from "@mui/material";
import projectsServices from "../../../services/projects.service";
import ProjectsModal from "./ProjectsModal/ProjectsModal";
import { help_center_urls } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    width: "99%",
    padding: "20px",
  },
  leftButton: {
    marginRight: "1rem",
  },
  error: {
    color: theme.palette.secondary.dark,
    fontWeight: "bold",
  },
  success: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
  },
  input: {
    border: 0,
    outline: 0,
    font: "inherit",
    backgroundColor: "transparent",
    width: "100%",
    textAlign: "center",
  },
  cell: {
    // whiteSpace: "nowrap",
    textAlign: "center",
  },
  undertimeHeader: {},
  noteCell: {
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  projectsCell: {
    textAlign: "center",
  },
  day: {
    textAlign: "left",
  },
  dayOff: {
    backgroundColor: theme.palette.grey.light,
  },
  bold: {
    fontWeight: "bold",
  },
  icon: {
    cursor: "pointer",
  },
}));

export default function Timesheet() {
  const styles = useStyles();
  const { t, i18n } = useTranslation("timesheets");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [days, setDays] = useState([]);
  const [user, setUser] = useState({});
  const [projects, setProjects] = useState([]);
  const [projectsModalOpen, setProjectsModalOpen] = useState(false);
  const [selectedDayForProjectsDetails, setSelectedDayForProjectsDetails] =
    useState();

  const timesheet = useSelector((state) => state.timesheets.timesheet);
  const isAdmin = useSelector((state) => state.account.isAdmin);
  const isTeamAdmin = useSelector((state) => state.account.isTeamAdmin);
  const users = useSelector((state) => state.account.users);
  const userSummaries = useSelector((state) => state.timesheets.userSummaries);
  const self = useSelector((state) => state.account.self);
  const dataLoading = useSelector((state) => state.feedback.loading);

  const [hasExtraOvertimeRule, setHasExtraOvertimeRule] = useState(false);
  const [hasCompensatoryDays, setHasCompensatoryDays] = useState(
    timesheet.active_config?.has_compensatory_days
  );
  const [hasOffHours, setHasOffHours] = useState(false);
  const [hasFamilyHours, setHasFamilyHours] = useState(false);
  const [familyHoursCustomName, setFamilyHoursCustomName] = useState(null);

  const params = useParams();
  const timesheetId = params.id;

  const locale = i18n.language;

  useEffect(() => {
    setHasOffHours(user.timesheet_columns?.has_off_hours);
    setHasFamilyHours(user.timesheet_columns?.has_family_hours);
    setFamilyHoursCustomName(user.timesheet_columns?.family_hours_custom_name);
  }, [user]);

  useEffect(() => {
    if ((isAdmin || isTeamAdmin) && users.length && timesheet.user_id) {
      const currentUser = users.find(
        (element) => element.id === timesheet.user_id
      );
      setUser(currentUser);
    } else setUser(self);
  }, [users, self]);

  useEffect(() => {
    if ((isAdmin || isTeamAdmin) && user && user.has_projects) {
      projectsServices.getProjectsForUser(user.id).then((result) => {
        setProjects(result.data);
      });
    } else if (self.has_projects) {
      projectsServices.getProjectsForSelf().then((result) => {
        setProjects(result.data);
      });
    }
  }, [user, self]);

  useEffect(() => {
    const hasOvertimeXtra = timesheet.active_config?.has_overtime_xtra_rule;
    setHasExtraOvertimeRule(hasOvertimeXtra);
    setHasCompensatoryDays(timesheet.active_config?.has_compensatory_days);
  }, [timesheet]);

  useEffect(() => findTimesheet(timesheetId), [timesheetId]);
  useEffect(() => displayDays(timesheet.days), [timesheet]);
  useEffect(() => {
    if (isAdmin && timesheet.user_id) {
      dispatch(getUser(timesheet.user_id));
      dispatch(getUserSummary(timesheet.user_id));
    } else if (
      isTeamAdmin &&
      timesheet.user_id &&
      timesheet.user_id !== self.id
    ) {
      dispatch(getUser(timesheet.user_id));
      dispatch(getUserSummary(timesheet.user_id));
    } else {
      dispatch(getSelf());
      dispatch(getSummaryForSelf());
    }
  }, [timesheet]);

  const findTimesheet = (timesheetID) => {
    dispatch(getTimesheet(timesheetID))
      .unwrap()
      .then((result) => {})
      .catch((err) => setError(t("timesheet_not_found")));
  };

  const displayDays = (rawDays) => {
    const formattedDays = buildDisplayDays(rawDays);
    setDays(formattedDays);
  };

  const handleChange = (event) => {
    let { id, value } = event.target;
    const [day, key] = id.split("-");
    const updatedDay = days.find((e) => e.day === parseInt(day));
    try {
      value = value.replace(",", ".");
      if (value.startsWith("0")) {
        updatedDay[key] = value.substring(1) || 0;
      } else if (key === "note") {
        updatedDay[key] = value || "";
      } else {
        updatedDay[key] = value || 0;
      }
      const newDays = replaceObjectWithKey(days, updatedDay, "day");
      displayDays(newDays);
    } catch {
      console.error("Not a number", value);
    }
  };

  const handleNext = () => {
    if (timesheet.next_id) {
      navigate(`/timesheet/${timesheet.next_id}`);
    }
  };

  const handlePrevious = () => {
    if (timesheet.previous_id) {
      navigate(`/timesheet/${timesheet.previous_id}`);
    }
  };

  const isDayWorkedDay = (day) => {
    return day.is_worked_day && day.is_in_current_fiscal_year;
  };

  const calculateSubTotal = (day) => {
    if (isDayWorkedDay(day)) {
      return round(
        parseFloat(day.worked_hours) +
          parseFloat(day.sick_hours) +
          parseFloat(day.vacation_hours) +
          parseFloat(day.off_hours) +
          parseFloat(day.family_hours) +
          parseFloat(hasCompensatoryDays ? day.compensatory_hours : 0)
      );
    } else {
      return round(parseFloat(day.worked_hours));
    }
  };

  const calculateUnderTime = (day) => {
    let value = 0;
    const isWorkedDay = isDayWorkedDay(day);
    if (isWorkedDay) {
      value = Math.max(
        0,
        timesheet.active_config.worked_hours_per_day - calculateSubTotal(day)
      );
    }
    return value;
  };

  const styleUnderTime = (day) => {
    const value = calculateUnderTime(day);

    let style;
    if (value > 0) {
      style = styles.error;
    }
    return <span className={style}>{value ? `${round(value)} h` : ""}</span>;
  };

  const calculateOverTime = (day) => {
    let overtime;

    const isWorkedDay = isDayWorkedDay(day);
    if (isWorkedDay) {
      const regularOvertime = Math.max(
        0,
        -(timesheet.active_config.worked_hours_per_day - calculateSubTotal(day))
      );
      overtime = regularOvertime + calculateExtraOverTimeForDay(day);
    } else {
      overtime = calculateSubTotal(day) + calculateExtraOverTimeForDay(day);
    }
    return overtime;
  };

  const calculateExtraOverTimeForDay = (day) => {
    let extraOvertime = 0;
    const hasOvertimeXtra = !!Object.entries(
      timesheet.active_config?.overtime_xtra || {}
    ).length;
    if (hasOvertimeXtra) {
      const overtimeXtraRule = timesheet.active_config.overtime_xtra;
      if (
        overtimeXtraRule.day_hours_before_xtra_1 &&
        overtimeXtraRule.day_hours_before_xtra_2
      ) {
        const bracket =
          overtimeXtraRule.day_hours_before_xtra_2 -
          overtimeXtraRule.day_hours_before_xtra_1;
        const xtra_1 =
          Math.min(
            bracket,
            Math.max(
              0,
              calculateSubTotal(day) - overtimeXtraRule.day_hours_before_xtra_1
            )
          ) *
          (overtimeXtraRule.day_overtime_xtra_1 - 1);
        const xtra_2 =
          Math.max(
            0,
            calculateSubTotal(day) - overtimeXtraRule.day_hours_before_xtra_2
          ) *
          (overtimeXtraRule.day_overtime_xtra_2 - 1);
        extraOvertime = xtra_1 + xtra_2;
      } else if (overtimeXtraRule.day_hours_before_xtra_1) {
        extraOvertime =
          Math.max(
            0,
            calculateSubTotal(day) - overtimeXtraRule.day_hours_before_xtra_1
          ) *
          (overtimeXtraRule.day_overtime_xtra_1 - 1);
      }
    }

    return extraOvertime;
  };

  const calculateExtraOverTimeForWeek = () => {
    // we set that to avoid counting double extra overtime on days and week
    let dailyHoursBeforeOvertime = 24;
    const overtimeXtraRule = timesheet.active_config.overtime_xtra;
    const hasOverTimeExtraForDay = overtimeXtraRule.day_overtime_xtra_1 !== 1;
    if (
      hasOverTimeExtraForDay &&
      overtimeXtraRule &&
      overtimeXtraRule.day_hours_before_xtra_1
    ) {
      dailyHoursBeforeOvertime = overtimeXtraRule.day_hours_before_xtra_1;
    }
    const total_hours = days.reduce(
      (acc, day) => acc + Math.min(dailyHoursBeforeOvertime, day._subTotal()),
      0
    );
    const extraDaysOvertime = days.reduce(
      (acc, day) => acc + calculateExtraOverTimeForDay(day),
      0
    );

    let extraWeekOvertime = 0;
    const hasOvertimeXtra = !!Object.entries(
      timesheet.active_config?.overtime_xtra || {}
    ).length;
    if (hasOvertimeXtra) {
      const overtimeXtraRule = timesheet.active_config.overtime_xtra;
      if (
        overtimeXtraRule.week_hours_before_xtra_1 &&
        overtimeXtraRule.week_hours_before_xtra_2
      ) {
        const bracket =
          overtimeXtraRule.week_hours_before_xtra_2 -
          overtimeXtraRule.week_hours_before_xtra_1;
        const xtra_1 =
          Math.min(
            bracket,
            Math.max(0, total_hours - overtimeXtraRule.week_hours_before_xtra_2)
          ) *
          (overtimeXtraRule.day_overtime_xtra_1 - 1);
        const xtra_2 =
          Math.max(0, total_hours - overtimeXtraRule.week_hours_before_xtra_2) *
          (overtimeXtraRule.week_overtime_xtra_2 - 1);
        extraWeekOvertime = xtra_1 + xtra_2;
      } else if (overtimeXtraRule.week_hours_before_xtra_1) {
        extraWeekOvertime =
          Math.max(0, total_hours - overtimeXtraRule.week_hours_before_xtra_1) *
          (overtimeXtraRule.week_overtime_xtra_1 - 1);
      }
    }
    return extraWeekOvertime + extraDaysOvertime;
  };

  const styleOvertime = (day) => {
    const value = calculateOverTime(day);
    let style;
    if (value > 0) {
      style = styles.success;
    }
    if (hasExtraOvertimeRule) {
      return (
        <Tooltip title={t("has_overtime_extra_rule")}>
          <span className={style}>{value ? `${round(value)} h` : ""}</span>
        </Tooltip>
      );
    }
    return <span className={style}>{value ? `${round(value)} h` : ""}</span>;
  };

  const setDaysOrderBasedOnFirstDay = (days, firstDay) => {
    days.sort((a, b) => a.day - b.day);
    const beginingOfWeek = days.splice(firstDay);
    return beginingOfWeek.concat(days);
  };

  const buildDisplayDays = (days) => {
    let displayDays = [];

    if (days) {
      displayDays = days.map((day) => {
        return {
          ...day,
          _subTotal: () => calculateSubTotal(day),
          _undertime: () => styleUnderTime(day),
          _overtime: () => styleOvertime(day),
        };
      });
      displayDays = setDaysOrderBasedOnFirstDay(
        displayDays,
        timesheet.week_first_day
      );
    }
    return displayDays;
  };

  const handleSave = () => {
    const newTimesheet = { ...timesheet };
    newTimesheet.status = "filled";
    newTimesheet.days = days;
    handleUpdateTimesheet(newTimesheet);
  };
  const handleSign = () => {
    const newTimesheet = { ...timesheet };
    newTimesheet.status = "signed";
    newTimesheet.days = days;
    handleUpdateTimesheet(newTimesheet);
  };
  const handleUnSign = () => {
    const newTimesheet = { ...timesheet };
    newTimesheet.status = "filled";
    handleUpdateTimesheet(newTimesheet);
  };
  const handleCounterSign = () => {
    const newTimesheet = { ...timesheet };
    newTimesheet.status = "countersigned";
    newTimesheet.days = days;
    handleUpdateTimesheet(newTimesheet);
  };

  const handleUpdateTimesheet = (newTimesheet) => {
    setLoading(true);
    dispatch(updateTimesheet(newTimesheet))
      .unwrap()
      .then(() => handleSuccess())
      .catch((err) => handleError(err));
  };

  const handleSuccess = () => {
    dispatch(displayAlert(t("update_success"), "success"));
    if ((isAdmin || isTeamAdmin) && user) {
      dispatch(getUser(user.id));
    }
    setLoading(false);
  };

  const handleError = (err) => {
    dispatch(displayAlert(t("error"), "error"));
    setLoading(false);
  };

  const calculateTotalForKey = (key) => {
    let values;
    if (key === "worked_hours") {
      values = days.map((day) => parseFloat(day[key]));
    } else {
      values = days
        .filter((day) => isDayWorkedDay(day))
        .map((day) => parseFloat(day[key]));
    }
    return values.reduce((acc, value) => {
      return acc + value;
    }, 0);
  };

  const calculateTotalUnderTime = () => {
    const values = [
      calculateTotalForKey("worked_hours"),
      calculateTotalForKey("sick_hours"),
      calculateTotalForKey("vacation_hours"),
      calculateTotalForKey("off_hours"),
      calculateTotalForKey("family_hours"),
    ];
    const subTotal = values.reduce((acc, value) => acc + value, 0);
    return Math.max(0, timesheet.summary?.hours_per_week - subTotal);
  };

  const calculateTotalOverTime = () => {
    const values = [
      calculateTotalForKey("worked_hours"),
      calculateTotalForKey("sick_hours"),
      calculateTotalForKey("vacation_hours"),
      calculateTotalForKey("off_hours"),
      calculateTotalForKey("family_hours"),
    ];
    const subTotal = values.reduce((acc, value) => acc + value, 0);
    const regularOvertime = Math.max(
      0,
      -(timesheet.summary?.hours_per_week - subTotal)
    );
    if (regularOvertime > 0) {
      const extraOvertime = calculateExtraOverTimeForWeek();
      return regularOvertime + extraOvertime;
    } else {
      return regularOvertime;
    }
  };

  const calculateTotalForCalculatedValue = (key) => {
    let values = [];
    switch (key) {
      case "_subTotal":
        values = days.map((day) => day._subTotal());
        return round(values.reduce((acc, value) => acc + value, 0));

      case "_undertime":
        return round(calculateTotalUnderTime())
          ? `${round(round(calculateTotalUnderTime()))} h`
          : "";

      case "_overtime":
        return round(calculateTotalOverTime())
          ? `${round(round(calculateTotalOverTime()))} h`
          : "";
    }
  };

  const fillWorkedHoursDefaultValue = (day) => {
    if (!timesheet.prefill) {
      return day.worked_hours;
    } else if (timesheet.status === "empty" && !day.updated) {
      const updatedDay = day;
      updatedDay.updated = true;
      updatedDay.worked_hours = timesheet.active_config.worked_hours_per_day;
      const newDays = replaceObjectWithKey(days, updatedDay, "day");
      displayDays(newDays);
      return updatedDay.worked_hours;
    } else {
      return day.worked_hours;
    }
  };

  const handleClickHelp = () => {
    window.open(help_center_urls[locale].fill_timesheet, "_blank").focus();
  };

  const handleEditProjects = (day) => {
    setSelectedDayForProjectsDetails(day);
    setProjectsModalOpen(true);
  };

  const handleCloseProjectsModal = () => {
    setProjectsModalOpen(false);
  };

  const handleSaveProjectHours = (projectHours, day) => {
    const updatedDay = days.find((e) => e.day === parseInt(day.day));
    updatedDay.project_hours = projectHours;
    const newDays = replaceObjectWithKey(days, updatedDay, "day");
    setDays(newDays);
    handleSave();
  };

  const defineButtonsToDisplay = (status) => {
    let buttons;
    switch (status) {
      case "empty":
        buttons = (
          <LoadingButton
            variant="contained"
            color="primary"
            component="label"
            onClick={handleSave}
            loading={loading}
            aria-label={t("save")}
          >
            {t("save")}
          </LoadingButton>
        );
        break;
      case "filled":
        buttons = (
          <>
            <LoadingButton
              variant="contained"
              color="primary"
              component="label"
              onClick={handleSign}
              className={styles.leftButton}
              loading={loading}
              aria-label={t("sign")}
            >
              {t("sign")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="primary"
              component="label"
              onClick={handleSave}
              loading={loading}
              aria-label={t("save")}
            >
              {t("save")}
            </LoadingButton>
          </>
        );
        break;
      case "signed":
        if (isAdmin || (isTeamAdmin && user && user.id !== self.id)) {
          buttons = (
            <>
              <LoadingButton
                variant="outlined"
                color="darkSecondary"
                component="label"
                onClick={handleUnSign}
                className={styles.leftButton}
                loading={loading}
                aria-label={t("unsign")}
              >
                {t("unsign")}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="primary"
                component="label"
                onClick={handleCounterSign}
                loading={loading}
                aria-label={t("countersign")}
              >
                {t("countersign")}
              </LoadingButton>
            </>
          );
        } else {
          buttons = (
            <>
              <LoadingButton
                variant="outlined"
                color="darkSecondary"
                component="label"
                onClick={handleUnSign}
                loading={loading}
                aria-label={t("unsign")}
              >
                {t("unsign")}
              </LoadingButton>
            </>
          );
        }
        break;
      case "countersigned":
        if (isAdmin || (isTeamAdmin && user && user.id !== self.id)) {
          buttons = (
            // <InfoBox level={"info"}>{t("signed_cannot_be_updated")}</InfoBox>
            <>
              <LoadingButton
                variant="outlined"
                color="darkSecondary"
                component="label"
                onClick={handleUnSign}
                loading={loading}
              >
                {t("uncountersign")}
              </LoadingButton>
            </>
          );
        } else {
          buttons = (
            <InfoBox level={"info"}>{t("signed_cannot_be_updated")}</InfoBox>
          );
        }
        break;
      default:
        buttons = <></>;
    }
    return buttons;
  };

  return dataLoading ? (
    <Spinner />
  ) : (
    <Grid container rowSpacing={4} columnSpacing={2} className={styles.root}>
      {error ? (
        <>
          <Grid item xs={12} md={5}>
            <InfoBox level={"error"}>{error}</InfoBox>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              color="primary"
              component="label"
              onClick={() => navigate(-1)}
            >
              {t("back_to_list")}
            </Button>
          </Grid>
        </>
      ) : (
        <>
          {projectsModalOpen && (
            <ProjectsModal
              projects={projects}
              display={projectsModalOpen}
              handleClose={handleCloseProjectsModal}
              handleSaveProjectHours={handleSaveProjectHours}
              day={selectedDayForProjectsDetails}
            />
          )}
          <Grid item xs={12} sm={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h2">{`${t("from")} ${formatDate(
                    timesheet.date_from,
                    locale
                  )} ${t("to")} ${formatDate(
                    timesheet.date_to,
                    locale
                  )}`}</Typography>
                </Grid>

                <Grid item xs={6} marginTop={"1rem"}>
                  {displayStatus(timesheet, t)}
                </Grid>
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"left"}>
                <Button onClick={handleClickHelp}>
                  <HelpIcon color="darkSecondary" />
                  <Typography
                    component="p"
                    variant="body1"
                    marginLeft={"1rem"}
                    // color="primary.dark"
                    style={{ textTransform: "none" }}
                  >
                    {t("help.title")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={5}>
            {user && user.id && userSummaries && userSummaries[user.id] && (
              <>
                <Typography
                  variant="h3"
                  component="p"
                  display={"flex"}
                  alignItems={"flex-end"}
                  color={"primary.darkest"}
                  marginBottom={"1rem"}
                >
                  {user.first_name} {user.last_name}
                </Typography>
                <UserSummary user={user} userSummary={userSummaries[user.id]} />
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            {!!timesheet.days && (
              <TableContainer component={Paper}>
                <form onChange={handleChange}>
                  <Table aria-label={t("timesheet")}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.dayCell}>
                          {t("day")}
                        </TableCell>
                        <TableCell className={styles.cell}>
                          {t("worked_hours")}
                        </TableCell>
                        <TableCell className={styles.cell}>
                          {t("sick_hours")}
                        </TableCell>
                        <TableCell className={styles.cell}>
                          {t("vacation_hours")}
                        </TableCell>
                        {hasOffHours ? (
                          <TableCell className={styles.cell}>
                            {t("off_hours")}
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        {hasFamilyHours ? (
                          <TableCell className={styles.cell}>
                            {familyHoursCustomName || t("family_hours")}
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        {hasCompensatoryDays ? (
                          <TableCell className={styles.cell}>
                            {t("undertime_hours")}
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        <TableCell className={styles.cell}>
                          {t("sub_total_hours")}
                        </TableCell>
                        {hasCompensatoryDays ? (
                          <></>
                        ) : (
                          <>
                            <TableCell className={styles.cell}>
                              {t("undertime_hours")}
                            </TableCell>
                            <TableCell className={styles.cell}>
                              {t("overtime_hours")}
                            </TableCell>
                          </>
                        )}
                        <TableCell className={styles.cell}>
                          {t("note")}
                        </TableCell>
                        {user && user.has_projects && (
                          <TableCell className={styles.cell}>
                            {t("projects")}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {days.map((day) => {
                        const isWorkedDay = isDayWorkedDay(day);

                        return isWorkedDay ? (
                          <TableRow hover key={day.day}>
                            <Tooltip title={day.date}>
                              <TableCell className={styles.dayCell}>
                                {t(`day_numbers.${day.day}`)}
                              </TableCell>
                            </Tooltip>
                            <TableCell className={styles.cell}>
                              <label
                                aria-hidden
                                hidden
                                htmlFor={`${day.day}-worked_hours`}
                              >
                                {`${day.day}-${t("worked_hours")}`}
                              </label>
                              <input
                                id={`${day.day}-worked_hours`}
                                className={styles.input}
                                defaultValue={fillWorkedHoursDefaultValue(day)}
                                //trick to force it to render with initial value
                                key={`${day.date}-worked_hours`}
                                // value={fillWorkedHoursDefaultValue(day)}
                              />
                            </TableCell>
                            <TableCell className={styles.cell}>
                              <label
                                aria-hidden
                                hidden
                                htmlFor={`${day.day}-sick_hours`}
                              >
                                {`${day.day}-${t("sick_hours")}`}
                              </label>
                              <input
                                id={`${day.day}-sick_hours`}
                                className={styles.input}
                                defaultValue={day.sick_hours}
                                value={day.sick_hours}
                              />
                            </TableCell>
                            <TableCell className={styles.cell}>
                              <label
                                aria-hidden
                                hidden
                                htmlFor={`${day.day}-vacation_hours`}
                              >
                                {`${day.day}-${t("vacation_hours")}`}
                              </label>
                              <input
                                id={`${day.day}-vacation_hours`}
                                className={styles.input}
                                defaultValue={day.vacation_hours}
                                value={day.vacation_hours}
                              />
                            </TableCell>
                            {hasOffHours ? (
                              <TableCell className={styles.cell}>
                                <label
                                  aria-hidden
                                  hidden
                                  htmlFor={`${day.day}-off_hours`}
                                >
                                  {`${day.day}-${t("off_hours")}`}
                                </label>
                                <input
                                  id={`${day.day}-off_hours`}
                                  className={styles.input}
                                  defaultValue={day.off_hours}
                                  value={day.off_hours}
                                />
                              </TableCell>
                            ) : (
                              <></>
                            )}
                            {hasFamilyHours ? (
                              <TableCell className={styles.cell}>
                                <label
                                  aria-hidden
                                  hidden
                                  htmlFor={`${day.day}-family_hours`}
                                >
                                  {`${day.day}-${t("family_hours")}`}
                                </label>
                                <input
                                  id={`${day.day}-family_hours`}
                                  className={styles.input}
                                  defaultValue={day.family_hours}
                                  value={day.family_hours}
                                />
                              </TableCell>
                            ) : (
                              <></>
                            )}
                            {hasCompensatoryDays ? (
                              <TableCell className={styles.cell}>
                                <label
                                  aria-hidden
                                  hidden
                                  htmlFor={`${day.day}-compensatory_hours`}
                                >
                                  {`${day.day}-${t("compensatory_hours")}`}
                                </label>
                                <input
                                  id={`${day.day}-compensatory_hours`}
                                  className={styles.input}
                                  defaultValue={day.compensatory_hours}
                                  value={day.compensatory_hours}
                                />
                              </TableCell>
                            ) : (
                              <></>
                            )}
                            <TableCell className={styles.cell}>
                              {day._subTotal()} h
                            </TableCell>
                            {hasCompensatoryDays ? (
                              <></>
                            ) : (
                              <>
                                <TableCell className={styles.cell}>
                                  {day._undertime()}
                                </TableCell>
                                <TableCell className={styles.cell}>
                                  {day._overtime()}
                                </TableCell>
                              </>
                            )}
                            <TableCell className={styles.noteCell}>
                              <OverflowTooltip
                                id={`${day.day}-note`}
                                className={styles.input}
                                defaultValue={day.note}
                                value={day.note}
                                label={`${day.day}-${t("note")}`}
                              />
                            </TableCell>
                            {user && user.has_projects && (
                              <TableCell className={styles.projectsCell}>
                                <AccessAlarmIcon
                                  className={styles.icon}
                                  onClick={() => handleEditProjects(day)}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        ) : (
                          <TableRow
                            hover
                            key={day.day}
                            className={styles.dayOff}
                          >
                            <Tooltip title={day.date}>
                              <TableCell className={styles.dayCell}>
                                {t(`day_numbers.${day.day}`)}
                              </TableCell>
                            </Tooltip>
                            <TableCell className={styles.cell}>
                              {day.is_in_current_fiscal_year ? (
                                <>
                                  <label
                                    aria-hidden
                                    hidden
                                    htmlFor={`${day.day}-worked_hours`}
                                  >
                                    {" "}
                                    {`${day.day}-${t("worked_hours")}`}
                                  </label>
                                  <input
                                    id={`${day.day}-worked_hours`}
                                    className={styles.input}
                                    defaultValue={day.worked_hours}
                                    value={day.worked_hours}
                                  />
                                </>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell className={styles.cell}>-</TableCell>
                            <TableCell className={styles.cell}>-</TableCell>
                            {hasOffHours ? (
                              <TableCell className={styles.cell}>-</TableCell>
                            ) : (
                              <></>
                            )}
                            {hasFamilyHours ? (
                              <TableCell className={styles.cell}>-</TableCell>
                            ) : (
                              <></>
                            )}
                            {hasCompensatoryDays ? (
                              <TableCell className={styles.cell}>-</TableCell>
                            ) : (
                              <></>
                            )}
                            <TableCell className={styles.cell}>
                              {day._subTotal()} h
                            </TableCell>
                            {hasCompensatoryDays ? (
                              <></>
                            ) : (
                              <>
                                <TableCell className={styles.cell}>-</TableCell>
                                <TableCell className={styles.cell}>
                                  {day._overtime()}
                                </TableCell>
                              </>
                            )}
                            <TableCell className={styles.noteCell}>
                              <OverflowTooltip
                                id={`${day.day}-note`}
                                className={styles.input}
                                defaultValue={day.note}
                                value={day.note}
                                label={`${day.day}-${t("note")}`}
                              />
                            </TableCell>
                            {user && user.has_projects && (
                              <TableCell className={styles.projectsCell}>
                                <AccessAlarmIcon
                                  className={styles.icon}
                                  onClick={() => handleEditProjects(day)}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                      <TableRow hover key={"total"}>
                        <TableCell className={styles.dayCell}>
                          <span className={styles.bold}>{t("total")}</span>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span className={styles.bold}>
                            {calculateTotalForKey("worked_hours")} h
                          </span>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span className={styles.bold}>
                            {calculateTotalForKey("sick_hours")} h
                          </span>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span className={styles.bold}>
                            {calculateTotalForKey("vacation_hours")} h
                          </span>
                        </TableCell>
                        {hasOffHours ? (
                          <TableCell className={styles.cell}>
                            <span className={styles.bold}>
                              {calculateTotalForKey("off_hours")} h
                            </span>
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        {hasFamilyHours ? (
                          <TableCell className={styles.cell}>
                            <span className={styles.bold}>
                              {calculateTotalForKey("family_hours")} h
                            </span>
                          </TableCell>
                        ) : (
                          <></>
                        )}

                        {hasCompensatoryDays ? (
                          <TableCell className={styles.cell}>
                            <span className={styles.bold}>
                              {calculateTotalForKey("compensatory_hours")} h
                            </span>
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        <TableCell className={styles.cell}>
                          <Tooltip title={t("total_hours_explaination")}>
                            <span className={styles.bold}>
                              {calculateTotalForCalculatedValue("_subTotal")} h
                            </span>
                          </Tooltip>
                        </TableCell>
                        {hasCompensatoryDays ? (
                          <></>
                        ) : (
                          <>
                            <TableCell className={styles.cell}>
                              <span className={styles.error}>
                                {calculateTotalForCalculatedValue("_undertime")}
                              </span>
                            </TableCell>
                            <TableCell className={styles.cell}>
                              {hasExtraOvertimeRule ? (
                                <Tooltip title={t("has_overtime_extra_rule")}>
                                  <span className={styles.success}>
                                    {calculateTotalForCalculatedValue(
                                      "_overtime"
                                    )}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span className={styles.success}>
                                  {calculateTotalForCalculatedValue(
                                    "_overtime"
                                  )}
                                </span>
                              )}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </form>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="outlined"
              component="label"
              color="darkPrimary"
              className={styles.leftButton}
              disabled={!timesheet.previous_id}
              onClick={handlePrevious}
              aria-label={t("previous")}
            >
              {t("previous")}
            </Button>
            <Button
              variant="outlined"
              component="label"
              color="darkPrimary"
              disabled={!timesheet.next_id}
              onClick={handleNext}
              aria-label={t("next")}
            >
              {t("next")}
            </Button>
          </Grid>
          <Grid item xs={12} md={6} display={"flex"} justifyContent={"right"}>
            {defineButtonsToDisplay(timesheet.status)}
          </Grid>
        </>
      )}
    </Grid>
  );
}
