import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TimesheetsService from "../services/timesheets.service";
import { displayAlert } from "./feedbackSlice";
import { manageError, replaceObjectWithKey } from "../utils/helpers";

// Fiscal Years
export const getFiscalYears = createAsyncThunk(
  "timesheets/fiscal-years/get",
  async (thunkAPI) => {
    try {
      const data = await TimesheetsService.getFiscalYears();
      return { fiscalYears: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createFiscalYear = createAsyncThunk(
  "timesheets/fiscal-years/post",
  async (fiscalYearData, thunkAPI) => {
    try {
      const data = await TimesheetsService.createFiscalYear(fiscalYearData);
      return { fiscalYear: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Timesheets
export const getTimesheetsForUser = createAsyncThunk(
  "timesheets/user/get",
  async ({ userId, displayPreviousYears = false }, thunkAPI) => {
    try {
      const data = await TimesheetsService.getTimesheetsForUser({
        userId,
        displayPreviousYears,
      });
      return { timesheets: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getTimesheetsForSelf = createAsyncThunk(
  "timesheets/self/get",
  async ({ displayPreviousYears }, thunkAPI) => {
    try {
      const data = await TimesheetsService.getTimesheetsForSelf({
        displayPreviousYears,
      });
      return { timesheets: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getTimesheet = createAsyncThunk(
  "timesheet/get",
  async (timesheetId, thunkAPI) => {
    try {
      const data = await TimesheetsService.getTimesheet(timesheetId);
      return { timesheet: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateTimesheet = createAsyncThunk(
  "timesheets/update/post",
  async (timesheetData, thunkAPI) => {
    try {
      const data = await TimesheetsService.updateTimesheet(timesheetData);
      return { timesheet: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getTimesheetsToCounterSign = createAsyncThunk(
  "timesheet/to-countersign/get",
  async (timesheetId, thunkAPI) => {
    try {
      const data = await TimesheetsService.getTimesheetsToCountersign();
      return { timesheets: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Summaries
export const getUserSummary = createAsyncThunk(
  "timesheet/summary/user/get",
  async (userId, thunkAPI) => {
    try {
      const data = await TimesheetsService.getUserSummary(userId);
      return { userSummary: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSummaryForSelf = createAsyncThunk(
  "timesheet/summary/self/get",
  async (thunkAPI) => {
    try {
      const data = await TimesheetsService.getSummaryForSelf();
      return { userSummary: data.data };
    } catch (error) {
      const message = manageError(error);
      thunkAPI.dispatch(displayAlert(message, "error"));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const timesheetsSlice = createSlice({
  name: "timesheets",
  initialState: {
    fiscalYears: [],
    timesheets: [],
    todo: { toCounterSign: [] },
    timesheet: {},
    loading: false,
    userSummaries: {},
  },
  reducers: {},
  extraReducers: {
    // FiscalYears
    [getFiscalYears.fulfilled]: (state, action) => {
      state.fiscalYears = action.payload.fiscalYears;
    },
    [createFiscalYear.fulfilled]: (state, action) => {
      state.fiscalYears = [action.payload.fiscalYear, ...state.fiscalYears];
    },
    //Timesheets
    [getTimesheetsForUser.fulfilled]: (state, action) => {
      const newTimesheets = action.payload.timesheets;
      newTimesheets.sort(
        (a, b) => new Date(b.date_from) - new Date(a.date_from)
      );
      // Quick and dirty fix to hide first week of the fiscal year if duplicate from last year
      const duplicateWeeks = newTimesheets.filter(
        (timesheet) => timesheet.date_from === "2023-03-27"
      );

      if (duplicateWeeks.length > 1) {
        duplicateWeeks.sort((a, b) => b.id - a.id);
        const weekToRemoveId = duplicateWeeks[0].id;
        const weekToRemoveIndex = newTimesheets.findIndex(
          (timesheet) => timesheet.id === weekToRemoveId
        );
        newTimesheets.splice(weekToRemoveIndex, 1);
      }
      state.timesheets = newTimesheets;
    },
    [getTimesheetsForSelf.fulfilled]: (state, action) => {
      const newTimesheets = action.payload.timesheets;
      newTimesheets.sort(
        (a, b) => new Date(b.date_from) - new Date(a.date_from)
      );

      // Quick and dirty fix to hide first week of the fiscal year if duplicate from last year
      const duplicateWeeks = newTimesheets.filter(
        (timesheet) => timesheet.date_from === "2023-03-27"
      );

      if (duplicateWeeks.length > 1) {
        duplicateWeeks.sort((a, b) => b.id - a.id);
        const weekToRemoveId = duplicateWeeks[0].id;
        const weekToRemoveIndex = newTimesheets.findIndex(
          (timesheet) => timesheet.id === weekToRemoveId
        );
        newTimesheets.splice(weekToRemoveIndex, 1);
      }
      state.timesheets = newTimesheets;
    },
    [getTimesheet.fulfilled]: (state, action) => {
      state.timesheet = action.payload.timesheet;
    },
    [getTimesheetsToCounterSign.fulfilled]: (state, action) => {
      state.todo.toCounterSign = action.payload.timesheets;
    },
    [updateTimesheet.fulfilled]: (state, action) => {
      const newTimesheet = action.payload.timesheet;
      state.timesheets = replaceObjectWithKey(
        state.timesheets,
        newTimesheet,
        "id"
      );
      state.timesheet = newTimesheet;
    },
    // Summaries
    [getUserSummary.fulfilled]: (state, action) => {
      const userSummary = action.payload.userSummary;
      state.userSummaries[userSummary.user_id] = userSummary;
    },
    [getSummaryForSelf.fulfilled]: (state, action) => {
      const userSummary = action.payload.userSummary;
      state.userSummaries[userSummary.user_id] = userSummary;
    },
  },
});

export default timesheetsSlice.reducer;
